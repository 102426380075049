import React from "react"
import { Layout, SEO } from "../../components/structure"
import { Hero, Content, CallToAction, Button, IconTile, Grid, Column, BlueBlock, BlogCard, GrayBlock } from "../../components/blocks"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import { mq } from "../../styles"
import styled, { css } from "styled-components";

import english from "../../../content/translations/en-CA/products.json"
import french from "../../../content/translations/fr-CA/products.json"

const BlogCon = styled.div`
  max-width: 1050px;
  margin: 60px auto 0 auto;
  padding: 60px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0 20px;
  flex-wrap: wrap;
  border-top: 1px solid #c4c4c5;
  @media only screen and ${mq.maxMd}{
    margin: 60px 25px 0 25px;
  }
  @media only screen and ${mq.maxSm}{
    flex-direction: column;
    align-items: center;
  }
`;
const ProductsPage = () => {

  let lang = english;

  const pageQuery = useStaticQuery(graphql`
    query {
      heroImage: file(base: { eq: "GettyImages-989518294.jpg" }) {
        ...HeroImage
      }
      icon01:  file(base: { eq: "icon-21.png" }) {
        ...IconTileImage
      }
      icon02:  file(base: { eq: "icon-02.png" }) {
        ...IconTileImage
      }
      icon03:  file(base: { eq: "icon-22.png" }) {
        ...IconTileImage
      },
      graph1:  file(base: { eq: "circle-26-percent.png" }) {
        childImageSharp {
          fixed(width: 165, height: 165, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      graph2:  file(base: { eq: "circle-59-percent.png" }) {
        childImageSharp {
          fixed(width: 165, height: 165, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      graph3:  file(base: { eq: "circle-5.png" }) {
        childImageSharp {
          fixed(width: 165, height: 165, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      gift:  file(base: { eq: "Product-Mockup-01.png" }) { 
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      accelerator:  file(base: { eq: "Product-Mockup-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keep:  file(base: { eq: "Product-Mockup-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog1: file(base: {eq: "2xLoyalty_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog2: file(base: {eq: "APAC_Airline_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog3: file(base: {eq: "Emirates_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      buy:  file(base: { eq: "buy.png" }) { 
        childImageSharp {
          fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={lang.lcr.meta.title}
        description={lang.lcr.meta.description}
      />
      <Hero 
        title={lang.lcr.hero.title}
        subtitle={<><span style={{ color: "white" }}>{lang.lcr.hero.subtitle}</span><br /><Button hero style={{ marginTop: '1rem' }} type={'white'} label={lang.lcr.hero.buttonText} destination={'/contact/schedule-a-call/'} /></>}
        image={pageQuery['heroImage']}
      />
      <Content angled lcrPage>
        <h2 style={{ textAlign: "center" }}>{lang.lcr.content.section_one.title}</h2>
        <div className={"grid grid-3x index"} style={{maxWidth: '1080px', margin: 'auto'}}>
        <div className="col col-30 CardBlock">
            <IconTile icon={pageQuery["icon03"]} border={false} lcrPage>
            <p style={{fontWeight: 'Bold', marginBottom: '15px'}}>{lang.lcr.content.section_one.icon_one}</p>
            </IconTile>
          </div>
          <div className="col col-30 CardBlock">
            <IconTile icon={pageQuery["icon02"]} style={{marginBottom: '0px'}} border={false}>
              <p style={{fontWeight: 'Bold', marginBottom: '15px'}}>{lang.lcr.content.section_one.icon_two}</p>
            </IconTile>
          </div>
          <div className="col col-30 CardBlock">
            <IconTile icon={pageQuery["icon01"]} style={{marginBottom: '0px'}} border={false}>
            <p style={{fontWeight: 'Bold'}}>{lang.lcr.content.section_one.icon_three}</p>
            </IconTile>
          </div>
        </div>
      </Content>
      <BlueBlock style={{ marginBottom: '0rem', maxWidth: '1050px' }}>
        <h2 className={"kl"} style={{ margin: '3rem 0 2rem' }}>{lang.lcr.content.section_two.title}</h2>
        <div className={"lcrPage"}>
          <div style={{ textAlign: "center", fontSize: '32px' }}>
            <GatsbyImage fixed={pageQuery["graph1"].childImageSharp.fixed} />
            <div style={{margin: '10px auto 5px'}}>{lang.lcr.content.section_two.icon_one.line_one}</div>
            <b>{lang.lcr.content.section_two.icon_one.line_two}</b>
          </div>
          <div style={{ textAlign: "center", fontSize: '32px' }}>
            <GatsbyImage fixed={pageQuery["graph2"].childImageSharp.fixed} />
            <div style={{margin: '10px auto 5px'}} dangerouslySetInnerHTML={{__html:lang.lcr.content.section_two.icon_two.line_one}}></div>
            <div>{lang.lcr.content.section_two.icon_two.line_two}</div>
          </div>
          <div style={{ textAlign: "center", fontSize: '32px' }}>
            <GatsbyImage fixed={pageQuery["graph3"].childImageSharp.fixed} />
            <div style={{margin: '10px auto 5px'}}>{lang.lcr.content.section_two.icon_three.line_one}</div>
            <b>{lang.lcr.content.section_two.icon_three.line_two}</b>
          </div>
        </div>
        {/* <h2 className={"kl"} style={{ marginTop: "4rem", marginBottom: '4rem' }}>Currency retailing solutions<br/>from Points</h2>
        <Grid columns={2}>
          <Column>
            <h4>Buy</h4>
            <p>Let members buy your currency at any point in their loyalty lifecycle. Whether they’re stocking up for
              the future or topping up to reach an immediate reward, buying points and miles gives your members more of what they
              want.</p>
            <ul className={"pts-checkmark-list"}>
              <li>Sell via white-labeled storefront</li>
              <li>Enable top up in your booking flow</li>
              <li>Let agents sell through your call centre</li>
              <li>Full marketing and promotional support</li>
            </ul>
          </Column>
          <Column>
            <GatsbyImage fluid={pageQuery["buy"].childImageSharp.fluid} />
          </Column>
        </Grid> */}
      
      </BlueBlock>
      <Content>
      <Grid columns={2}>
      {/* <Column >
            <GatsbyImage fluid={pageQuery["buy"].childImageSharp.fluid} />
          </Column> */}
          <Column style={{ textAlign: 'center'}} lcrPageMore>
            <h4>{lang.lcr.content.section_three.title}</h4>
            <p style={{fontSize: 19}} id="lcr-get">{lang.lcr.content.section_three.copy}</p>
          </Column>
          
        </Grid>
        
      </Content>
      
      <GrayBlock>
      
      <Content whiteOnGray lcrPage className={'get-image-con'}>
      
        <Grid columns={2} lcrGrid>
        <Column className="left-content">
            <GatsbyImage fluid={pageQuery['gift'].childImageSharp.fluid} className={'lcrColImage get'}/>
        </Column>
          <Column className="right-content" style={{ textAlign: 'left', marginRight: '25px'}} >
            <h4>{lang.lcr.content.section_four.get.title}</h4>
            <p style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html:lang.lcr.content.section_four.get.copy}}></p>
            <ul className={"pts-checkmark-list pts-checkmark-list-white"}>
              <li className="sell"><b>{lang.lcr.content.section_four.get.list_one}</b></li>
              <li className="accelerate"><b>{lang.lcr.content.section_four.get.list_two}</b></li>
              <li className="topup"><b>{lang.lcr.content.section_four.get.list_three}</b></li>
              <li className="elite"><b>{lang.lcr.content.section_four.get.list_four}</b></li>
            </ul>
          </Column>
          
        </Grid>
        <div id="lcr-move"></div>
      </Content>
      
      <Content whiteOnGray lcrPage className={'moveContent'}>
        <Grid columns={2} lcrGrid>
          
          <Column className="left-content" style={{marginLeft: '25px'}}  >
            <h4>{lang.lcr.content.section_four.move.title}</h4>
            <p style={{ textAlign: 'left'}} dangerouslySetInnerHTML={{__html:lang.lcr.content.section_four.move.copy}}></p>
            <ul className={"pts-checkmark-list"}>
              <li className="gift"><b>{lang.lcr.content.section_four.move.list_one}</b></li>
              <li className="transfer"><b>{lang.lcr.content.section_four.move.list_two}</b></li>
              <li className="exchange"><b>{lang.lcr.content.section_four.move.list_three}</b></li>
            </ul>
          </Column>
          <Column style={{ textAlign: 'center' }} className="right-content">
            <GatsbyImage fluid={pageQuery["accelerator"].childImageSharp.fluid} className={'lcrColImage move'}  />
          </Column>
        </Grid>
        <div id="lcr-keep"></div>
      </Content>
      <Content whiteOnGray lcrPage className={'keepContent'}>
        <Grid columns={2} lcrGrid>
          <Column className="left-content">
            <GatsbyImage fluid={pageQuery['keep'].childImageSharp.fluid} className={'lcrColImage keep'}/>
          </Column>
          <Column style={{ textAlign: 'left', marginRight: '25px', width: '45%!important'}} className="right-content keep-right-content" >
            <h4>{lang.lcr.content.section_four.keep.title}</h4>
            <p style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html:lang.lcr.content.section_four.keep.copy}}></p>
            <ul className={"pts-checkmark-list pts-checkmark-list-white"}>
              <li className="reinstate"><b>{lang.lcr.content.section_four.keep.list_one}</b></li>
              <li className="extend"><b>{lang.lcr.content.section_four.keep.list_two}</b></li>
            </ul>
          </Column>
          
        </Grid>
      </Content>
      
      
      <BlogCon>
          <BlogCard 
            image={pageQuery["blog1"]} 
            toURL={lang.lcr.content.blog_content.blog_one.linkURL} 
            copy={lang.lcr.content.blog_content.blog_one.copy} 
            imgStyle={{height: "150%"}}
          />
          <BlogCard 
            image={pageQuery["blog2"]} 
            toURL={lang.lcr.content.blog_content.blog_two.linkURL} 
            copy={lang.lcr.content.blog_content.blog_two.copy} 
            imgStyle={{height: "100%", objectPosition: "left center"}}
          />
          <BlogCard 
            image={pageQuery["blog3"]} 
            toURL={lang.lcr.content.blog_content.blog_three.linkURL} 
            copy={lang.lcr.content.blog_content.blog_three.copy} 
            imgStyle={{height: "100%", objectPosition: "right center"}}
          />
       </BlogCon>
      </GrayBlock>
      <CallToAction title={lang.lcr.call_to_action.title}>
        <p>{lang.lcr.call_to_action.copy}</p>
        <Button destination={"/contact/schedule-a-call/"} type={"white"} label={lang.lcr.call_to_action.buttonText}/>
      </CallToAction>
    </Layout>
  )
}

export default ProductsPage
